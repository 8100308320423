@import 'styles/flex-mixins';
@import 'styles/mixins';

.control_panel {
    @include row(center, center, 10px);

    position: absolute;

    height: 40px;
    padding: 11px 16px;

    color: var(--primary-01);

    background-color: var(--neutral-10);
    border: 1px solid var(--primary-01-light);
    border-radius: 10px;

    .play_button_container {
        min-width: 25px;
        height: 25px;

        .play_button {
            &--play {
                border-width: 6px 0 6px 8px;
            }

            &--pause {
                width: 8px;
                height: 10px;

                border-right-width: 2px;
                border-left-width: 2px;
            }
        }
    }

    .icon {
        cursor: pointer;

        fill: var(--primary-01);
    }

    .tooltip {
        position: relative;

        display: inline-flex;
        align-items: center;
    }

    .tooltip .tooltiptext {
        position: absolute;
        z-index: 2;
        top: 150%;
        left: 50%;
        transform: translateX(-50%);

        width: fit-content;
        padding: 5px;

        font-size: 0.85rem;
        color: var(--neutral-00);
        text-align: center;

        visibility: hidden;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 6px;
        box-shadow: 0 2px 3px #0000001f;
    }

    .tooltip .tooltiptext::after {
        content: '';

        position: absolute;
        bottom: 100%;
        left: 50%;

        margin-left: -5px;

        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 5px;
    }

    .tooltip:hover .tooltiptext {
        visibility: visible;
    }

    svg.disabled {
        fill: var(--neutral-08);
    }
}

.choose_dict_modal {
    width: 100%;

    .input_wrapper {
        margin-bottom: 10px;
    }

    .button_wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .dicts_list {
        @include column(center, center, 0);

        margin-bottom: 10px;

        &__item {
            cursor: pointer;

            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            height: 25px;
            padding: 1rem;

            border-radius: 8px;

            &:hover,
            &:focus {
                background-color: var(--neutral-09);
            }
        }

        &__item.selected {
            background-color: var(--neutral-07);
        }
    }
}
