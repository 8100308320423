@import 'styles/flex-mixins';
@import 'styles/media';

.profile_page_container {
    @include row(flex-start, flex-start, 10%);
    @include landscape-max-width {
        width: 90%;
    }
    align-self: center;

    width: 80%;
    height: 100%;
    padding-top: 5%;

    .navigation_section {
        @include tablet-max-width {
            min-width: auto;
            margin: 0 5% 0 0;
        }
        min-width: 180px;

        .avatar {
            width: 100%;
            min-width: 100px;
            max-width: 200px;
            max-height: 200px;
            margin-bottom: 20px;

            border-radius: 25px;
        }

        .username {
            margin-bottom: 30px;

            font-family: MontserratSemibold, serif;
            font-size: 20px;
            line-height: 130%;
            color: var(--neutral-00);
        }

        .navigation_links {
            @include column(flex-start, center, 3vmin);

            .link {
                font-family: MontserratRegular, serif;

                &--active {
                    font-family: MontserratSemibold, serif;
                    color: var(--primary-01);
                }
            }
        }
    }

    .actions_section {
        flex-grow: 1;
    }
}
