@import 'styles/media';

.transcript-loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 100vh;
}

.transcript_page {
    overflow-y: auto;

    height: 100%;
    max-height: calc(100vh - var(--header-height) - var(--header-top-margin));
    padding-top: 3vh;

    .transcript_page_header {
        .back_arrow {
            cursor: pointer;

            margin: 0 var(--side-margin) 24px;

            color: var(--neutral-02);

            &::before {
                content: url('/assets/arrow-without-body.svg');

                transform: rotate(90deg);

                display: inline-block;

                margin: 0 10px;

                filter: opacity(0.6);
            }
        }

        .wrapper {
            &::after {
                content: url('/assets/pen-in-square.svg');

                width: 15px;
                margin-left: 15px;

                visibility: hidden;
            }

            &:hover::after {
                visibility: visible;
            }
        }

        .page_title {
            width: fit-content;
            padding: 5px 15px;

            border: 1px solid transparent;
            border-radius: var(--default-border-radius);

            &--editable {
                cursor: pointer;

                &::after {
                    content: url('/assets/pen-in-square.svg');

                    width: 15px;
                    margin-left: 15px;

                    visibility: hidden;
                }

                &:focus {
                    border: 1px solid var(--primary-01);
                }

                &:hover::after {
                    visibility: visible;
                }
            }
        }
    }

    .section_left {
        @include laptop-s-max-width {
            max-width: 100%;
        }

        flex: 1;

        max-width: 70%;
    }

    .section_right:not(:empty) {
        // for comment section
        // @include laptop-s-min-width {
        //     width: 5%;
        //     min-width: fit-content;

        //     &:has(.opening) {
        //         width: 35%;
        //         min-width: initial;

        //         transition: width 0.4s ease-out;
        //     }
        // }

        width: 40%;
        margin-left: 12px;
        padding-right: var(--side-margin) !important;
        padding-left: 45px;

        border-left: 2px solid var(--primary-01-light);

        #show-comments {
            display: none;
        }

        .open_icon {
            cursor: pointer;

            width: 25px;
            height: auto;
        }
    }

    &.protocol_fullscreen {
        .section_left {
            display: none;
        }

        .section_right:not(:empty) {
            width: 100%;
        }
    }
}
