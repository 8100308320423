.transcript_search {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;

    .founded_words_arrow {
        rotate: 90deg;
    }
}
