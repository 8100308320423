.demo-viewer {
    width: 100%;

    .btn_with_icon {
        background: transparent;
    }

    &__header,
    &__text-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 5px;

        h2 {
            font-size: 20px;
        }

        button {
            color: var(--primary-01);

            span {
                font-size: 14px;
            }
        }
    }

    &__text-header {
        flex-wrap: wrap;
        justify-content: start;

        border-top: 2px solid var(--primary-01-light);

        button {
            color: var(--primary-01-dark);

            span {
                font-weight: 600;
            }
        }
    }

    &__spinner {
        display: flex;
        justify-content: center;

        margin-top: 30px;
    }

    &__create-button {
        margin: auto;

        font-size: 14px !important;
    }

    &__editor {
        background-color: white;
        border-radius: var(--default-border-radius);

        &__button-container {
            display: flex;
            gap: 10px;
            justify-content: flex-end;

            padding: 10px;
        }
    }

    .tooltip {
        position: relative;
    }

    .tooltip .tooltip_text {
        position: absolute;
        z-index: 2;
        top: 100%;
        left: 0%;

        width: fit-content;
        min-width: 80px;
        padding: 5px;

        font-size: 0.85rem;
        text-align: center;

        visibility: hidden;
        background-color: #fff;
        border: 1px solid #fff;
        border-radius: 6px;
        box-shadow: 0 2px 3px #0000001f;
    }

    .tooltip .tooltip_text::after {
        content: '';

        position: absolute;
        bottom: 100%;
        left: 50%;

        margin-left: -5px;

        border-color: transparent transparent #fff;
        border-style: solid;
        border-width: 5px;
    }

    .tooltip:hover .tooltip_text {
        visibility: visible;
    }
}

.projects-viewer {
    &__spinner {
        display: flex;
        justify-content: center;

        margin: 30px 0;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        margin-top: 20px;
    }

    .back_arrow {
        cursor: pointer;

        margin: 0 0 24px;

        color: var(--neutral-02);

        &::before {
            content: url('/assets/arrow-without-body.svg');

            transform: rotate(90deg);

            display: inline-block;

            margin: 0 10px;

            filter: opacity(0.6);
        }
    }
}
