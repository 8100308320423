@import 'styles/flex-mixins';
@import 'styles/on-click';
@import 'styles/media';
@import 'styles/generics';

.main_page_header {
    @include row(space-between, center, 0);
    @include big-desktop-min-width {
        > * {
            transform: scale(1.2);
        }
    }
    @include landscape-min-width {
        > * {
            transform: scale(1);
        }
    }
    @include landscape-max-width {
        .logo {
            height: 35px;

            &-img {
                width: 50px;
                height: 50px;
            }
        }
    }
    @include laptop-s-max-width {
        > .logo,
        .hamburger {
            transform: scale(0.9);
        }
    }
    @include tablet-max-width {
        .hamburger {
            transform: scale(0.7);
        }
    }

    position: fixed;
    z-index: 10;
    top: 0;

    width: 100vw;
    height: var(--landing-header-height);
    padding: 2%;

    background: var(--neutral-10);

    .logo {
        @include row(flex-start, center, 12px);
        @include onclick-fade;
        cursor: pointer;

        margin-right: 20px;

        &-img {
            @include tablet-max-width {
                width: 40px;
                height: 40px;
            }
            position: relative;

            width: 61px;
            height: 60px;

            &::before {
                content: '';

                position: absolute;
                z-index: -1;
                top: 9px;
                right: 3px;
                bottom: -3px;
                left: 3px;

                background: rgb(255 199 0 / 50%);
                filter: blur(24px);
            }
        }

        &-text {
            @include tablet-max-width {
                width: 110px;
                height: 20px;
            }
            width: 193px;
            height: 30px;
        }
    }

    .links_desktop_container {
        @include row(space-between, center, 36px);

        @include laptop-s-max-width {
            @include row(space-between, center, 18px);

            text-align: center;
        }
    }

    .links {
        .link {
            @include landscape-max-width {
                font-size: 0.9rem;
                letter-spacing: 0.02em;
            }
            cursor: pointer;

            display: flex;
            align-items: center;

            font-size: 1.2rem;
            color: #6d7c83;
            text-transform: uppercase;

            &:hover,
            &--active {
                color: #f37800;
            }

            > .text {
                font-size: inherit;
            }

            .icon {
                width: 26px;
                height: 26px;
                margin-left: 0.5em;
            }
        }
    }

    .dropdown_wrapper {
        .dropdown_menu {
            width: 200px;

            border-top: 1px solid var(--orange);

            .links_mobile_container {
                @include column(center, center, 20px);
                padding: 10px;
            }
        }
    }
}
