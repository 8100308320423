@import 'styles/flex-mixins';
@import 'styles/media';

#features {
    .content {
        @include column(center, center, 47px);

        @include tablet-max-width {
            @include column(center, center, 36px);
            width: 100%;
        }

        max-width: 60%;
    }

    .title {
        padding-top: 42px;

        font-size: 32px;
        line-height: 36px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: -0.05em;
    }

    .features {
        @include row(center, flex-start, 5vw);
        @include tablet-max-width {
            @include column(center, center, 5vh);
        }

        &__item {
            @include column(center, flex-start, 10px);

            &-img {
                @include tablet-max-width {
                    width: 88px;
                    height: 88px;
                }
                width: 141px;
                height: 141px;

                &_big {
                    z-index: 1;
                    transform: scale(1.15);
                }
            }

            &-subtitle {
                max-width: 240px;

                font-family: Roboto, serif;
                text-align: center;
                letter-spacing: 0;
            }
        }
    }

    .sub-info {
        @include tablet-max-width {
            @include column(center, center, 10px);
        }

        @include column(center, center, 3px);
        width: 100%;

        &__divider {
            @include tablet-max-width {
                width: calc(100% - 12px);
                margin-left: -12px;
            }

            @include row(center, center, 8px);
            width: 100%;

            &-line {
                flex-grow: 1;

                height: 3px;

                background: #ffaa1e;
            }

            &-img {
                width: 43px;
                height: 33px;
            }
        }

        &__text {
            @include tablet-max-width {
                max-width: 70%;
            }

            font-family: Roboto, serif;
            text-align: center;
            letter-spacing: 0;
        }
    }
}
