@import 'styles/mixins';

.default_select {
    @include appearance(none);

    padding: 0.5rem 0.75rem;
    padding-right: 2.5rem;

    line-height: 1.25rem;

    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 1.5em 1.5em;
    border: 1px solid var(--neutral-04);
    border-radius: 0.25rem;
    outline: none;

    &:hover {
        border-color: var(--neutral-05);
    }

    &:focus {
        border-color: var(--primary-01);
    }
}
