@import 'styles/flex-mixins';

.share-redirection {
    @include column(center, center, 20px);

    align-self: center;

    width: 65%;
    max-width: 700px;
    height: 100%;

    p {
        font-size: 1.4rem;
        color: var(--primary-01-dark);
    }
}
