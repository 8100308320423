@import 'styles/generics';
@import 'styles/media';
@import 'styles/flex-mixins';

.profile_password_section {
    @include box-shadow-default;
    @include laptop-s-max-width {
        width: 100%;
    }
    position: relative;

    width: 640px;
    padding: 40px 40px 60px;

    background-color: var(--neutral-10);
    border-radius: 20px;

    .cross {
        position: absolute;
        top: 30px;
        right: 30px;

        width: 24px;
        height: 24px;
    }

    .section_title {
        margin-bottom: 1vmin;

        font-family: MontserratSemibold, serif;
        font-size: 20px;
        line-height: 26px;
        color: var(--neutral-00);
        text-align: center;
    }

    .error_loader_container {
        @include column(center, center, 0);
        align-self: center;

        width: 100%;
        height: 4vmin;
        margin: 0 auto;
    }

    .profile_password_form {
        @include column(center, center, 4vmin);
        @include laptop-s-max-width {
            width: 80%;
        }
        @include landscape-max-width {
            width: 100%;
        }
        width: 50%;
        margin: auto;

        .btn {
            width: 100%;
        }

        .error_message {
            text-align: center;
        }
    }
}
