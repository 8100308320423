::-webkit-scrollbar-button {
    width: var(--scroll-width);
    height: 0;

    background-repeat: no-repeat;
}

::-webkit-scrollbar-track {
    background-color: var(--neutral-07);
    background-clip: padding-box;
    border: 6px solid transparent;
    border-radius: var(--default-border-radius);
}

::-webkit-scrollbar-thumb {
    background-color: var(--neutral-05);
    background-clip: padding-box;
    border: 6px solid transparent;
    border-radius: var(--default-border-radius);
}

::-webkit-resizer {
    width: 4px;
    height: 0;

    background-repeat: no-repeat;
}

::-webkit-scrollbar {
    width: var(--scroll-width);
    height: var(--scroll-width);
}
