@import 'styles/flex-mixins';
@import 'styles/on-click';
@import 'styles/media';
@import 'styles/generics';

.main_page {
    .sections_container {
        position: relative;
        top: var(--landing-header-height);

        display: flex;
    }

    .curtains {
        @include mobile-height {
            display: none;
        }

        position: fixed;
        z-index: 6;
        top: var(--landing-header-height);
        bottom: 0;

        width: 100%;

        background: #fff;
        clip-path: polygon(
            0 0,
            100% 0,
            100% 67px,
            41% 9px,
            0 58px,
            0 calc(100vh - 96px),
            100% calc(100vh - 96px),
            100% calc((100vh - 96px) - 163px),
            69% calc((100vh - 96px) - 107px),
            0 calc((100vh - 96px) - 148px)
        );
    }
}
