@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/on-click';

.menu {
    @include column(flex-start, center, 10px);
    @include mobile-height {
        height: calc(100vh - var(--landing-header-height));
    }
    position: fixed;
    z-index: 5;
    right: 0;

    width: 30%;
    height: calc(100vh - var(--landing-header-height) - var(--footer-height));

    transition: 0.3s width ease-out;

    &_background {
        background: #ffab1e url('/assets/menu/menu-background.svg') no-repeat top right;
    }

    &--open {
        width: 77%;

        background: #ffab1e;
        background-image: url('/assets/menu/menu-background.svg'), url('/assets/menu/menu-background-left-down.svg');
        background-repeat: no-repeat;
        background-position: top right, bottom left;
    }

    .menu-nav {
        @include column(flex-start, flex-start, 48px);

        @include mobile-height {
            top: 50%;
        }

        @include big-desktop-min-width {
            @include column(flex-start, flex-start, 60px);
            left: 20%;

            > * {
                transform: scale(1.3);
            }
        }

        margin-left: 3vw;

        &__item {
            @include row(flex-start, center, 9px);

            @include onclick-fade;

            cursor: pointer;

            width: 100%;

            color: black;

            &:hover {
                @include big-desktop-min-width {
                    transform: scale(1.32);
                }
                transform: scale(1.02);
            }

            .arrow-left {
                width: 6px;
                height: 12px;
            }

            &-icon {
                width: 36px;
                height: 36px;
            }

            &-text {
                font-family: 'Graphik LCG-bold', serif;
                font-size: 18px;
                line-height: 36px;
                text-align: left;
                text-transform: uppercase;
                letter-spacing: 0.05em;
            }
        }
    }

    .authorization_container {
        @include big-desktop-min-width {
            > * {
                transform: scale(1.3);
            }
        }

        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 80%;
        min-height: 60%;
        margin: 10px auto;

        .content {
            @include row(center, flex-start, 36px);

            @include landscape-max-width {
                width: 80%;
            }

            .section_form {
                @include column(center, center, 20px);
                @include landscape-min-width {
                    width: 270px;
                }

                width: 100%;
            }

            .section_actions {
                @include column(flex-start, center, 1vh);
                @include landscape-min-width {
                    width: 270px;
                    padding-top: 100px;
                }

                width: 100%;
                padding-top: 20px;
            }

            .title_img {
                width: 60px;
                height: 60px;
                margin-bottom: 5px;
            }
        }

        .close_button {
            @include row(flex-start, center, 6px);
            @include onclick-fade;
            cursor: pointer;

            position: absolute;
            top: 0;
            right: 0;

            color: black;

            &:hover {
                @include big-desktop-min-width {
                    transform: scale(1.32);
                }
                transform: scale(1.02);
            }

            .icon {
                width: 30px;
                height: 30px;
            }

            .text {
                font-size: 18px;
                line-height: 36px;
                text-align: left;
                text-transform: uppercase;
                letter-spacing: 0.05em;
            }
        }
    }

    .link {
        @include tablet-max-width {
            line-height: 0.85;
            color: #6d7c83;
        }

        color: #004756;

        &:hover {
            opacity: 0.8;
        }

        &:active {
            opacity: 0.6;
        }
    }
}

.register_container,
.login_container,
.reset_password_container {
    @include tablet-max-width {
        @include column(center, center, 2vh);

        align-self: center;

        width: 65%;
        height: 100%;

        .section_form {
            @include column(center, center, 2vh);
            width: 100%;

            .input {
                background-color: var(--main-bc);
            }
        }
    }
}
