@import 'styles/media';

.stepper_wrapper {
    @include laptop-xs-max-width {
        margin-right: auto;
        margin-left: auto;
    }
    @include tablet-max-width {
        width: 420px;
    }
    @include mobile-xl-max-width {
        width: 100%;
    }
    width: clamp(380px, 75vw, 90%);
    min-height: 24px;
    margin-bottom: 3vh;

    .stepper {
        display: flex;
        flex: 1;
        align-items: center;

        max-width: 550px;

        .line {
            content: '';

            position: relative;

            flex: 1;

            min-width: 21px;
            margin: 0 clamp(0.25rem, 2vw, 0.75rem);

            border-top: 2px dashed var(--neutral-05);

            &--active {
                border-color: var(--primary-01);
            }
        }
    }

    .stepper__item {
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: MontserratRegular, serif;
        font-size: clamp(1rem, 2.5vw, 1.25rem);
        color: var(--neutral-05);
        text-align: center;

        &--current {
            font-family: MontserratSemibold, serif;
            color: var(--primary-01);
        }
    }

}
