@import 'styles/flex-mixins';

.share-modal {
    width: 100%;
    padding: 0 15px;

    &__body {
        width: 550px;

        background-color: white;
    }

    &__title p {
        margin: auto;
        padding-left: 1rem;
    }

    > p {
        font-size: 0.8rem;
        color: var(--neutral-02);
    }

    &__role {
        @include row(_, center, 20px);

        margin: 20px 0;

        &__dropdown {
            cursor: pointer;
            user-select: none;

            position: relative;

            text-align: center;

            .dropdown__header {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: space-between;

                min-width: 170px;
                height: 36px;
                padding: 12px;

                &__title {
                    overflow: hidden;

                    font-size: 14px;
                    color: var(--neutral-04);
                    text-overflow: ellipsis;
                }
            }

            .dropdown_menu {
                width: 100%;
            }

            .dropdown_menu__item {
                padding: 0.3rem 1rem;

                > span {
                    overflow: hidden;

                    width: 100%;

                    font-size: 14px;
                    text-align: left;
                    text-overflow: ellipsis;
                }
            }
        }
    }

    &__invite {
        @include column(flex-start, flex-start, 10px);

        margin: 20px 0;
    }

    &__footer {
        @include row(space-between, _, 5px);
    }

    &__create-link {
        @include column(center, flex-start, 25px);

        margin: 15px 0 20px;

        > div {
            display: flex;
            flex-direction: row;
            gap: 12px;
            align-items: flex-end;
            justify-content: space-between;

            width: 100%;
        }

        label {
            flex-grow: 1;

            font-size: 11px;
            line-height: 14px;
            color: var(--neutral-04);

            input {
                margin-top: 6px;
            }
        }

        > p {
            font-size: 0.8rem;
            color: var(--neutral-02);
        }
    }
}
