.replica__word {
    color: var(--gray-170);
    overflow-wrap: anywhere;
    white-space: pre-wrap;

    &--underline {
        background-color: var(--primary-02-light);
    }

    &--crossed-out {
        text-decoration: line-through;

        background-color: var(--red-light);
    }

    &--hidden {
        display: none;
    }

    &--highlighted {
        color: var(--primary-01);

        outline: none;
    }

    &--searched {
        background-color: var(--orange);
        outline: none;
    }

    &--viewed &--searched {
        background-color: var(--service-01);
    }
}
