@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/mixins';

.adding_word_form {
    width: 100%;

    .btn {
        width: 100%;
        margin: 20px 0 10px;
    }
}

.dictionary_page {
    padding: 6vmin 7vw;

    .back_arrow {
        cursor: pointer;

        margin: 0 0 24px;

        color: var(--neutral-02);

        &::before {
            content: url('/assets/arrow-without-body.svg');

            transform: rotate(90deg);

            display: inline-block;

            margin: 0 10px;

            filter: opacity(0.6);
        }
    }

    .page_title {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 40px;

        h2 {
            font-family: MontserratBold, serif;
            font-size: clamp(28px, 9vw, 35px);
        }

        span {
            margin-left: 25px;

            font-size: 18px;
            color: var(--neutral-02);
        }
    }

    .search_and_add_line {
        @include row(space-between, center, 20px);

        .search_bar {
            max-width: 580px;
        }

        .btn {
            width: 180px;
        }

        svg {
            fill: var(--primary-02-dark);
        }
    }

    .dictionary_content {
        @include wrap(flex-start, center, 10px);
        max-height: 500px;
        margin-top: 20px;

        .word_card {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 255px;
            height: 36px;
            margin-right: 46px;
            padding: 0 10px;

            background-color: var(--neutral-08);
            border-radius: 10px;

            .name {
                color: var(--neutral-02);
            }

            .actions {
                position: relative;

                display: flex;
                align-items: center;

                .icon {
                    @include transition(color 0.5s);

                    cursor: pointer;

                    color: #D6D6D6;

                    visibility: hidden;

                    &:hover {
                        color: var(--primary-01);
                    }

                    &:not(:last-of-type) {
                        margin-right: 15px;
                    }
                }

                .modal_content {
                    position: absolute;
                    top: 0;
                    left: 35px;

                    width: 330px;

                    background-color: white;
                    border-radius: 20px;
                    box-shadow: 0 2px 70px #0000000d;

                    .editing_word_form {
                        width: 100%;

                        .input_wrapper {
                            margin-bottom: 20px;
                        }

                        .btn {
                            width: 100%;
                        }
                    }
                }
            }

            &:hover {
                .actions .icon {
                    visibility: visible;
                }
            }
        }
    }
}
