@import 'styles/flex-mixins';
@import 'styles/media';

.uploader_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: 250px;

    .error_message {
        font-size: 14px;
        text-align: center;
    }

    .active_uploading {
        @include column(center, center, 10px);
        text-align: center;

        .name {
            font-size: 1.1rem;
            color: var(--primary-01);
        }

        .cancel {
            cursor: pointer;

            font-size: 0.9rem;
            color: var(--primary-01-light-text);

            border-bottom: 1px dotted var(--primary-01-light-text);
        }
    }

    .uploader_form {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        min-height: 250px;
        margin-bottom: 15px;

        .button {
            margin-top: 10px;
        }

        .drag_drop_area {
            width: 100%;
            height: 100%;

            background-color: #fdfcfb;
            border: 2px dashed #e5d9ff;
            border-radius: 40px;

            .tooltip {
                position: relative;

                display: inline-block;

                font-family: inherit;
            }

            .tooltip .tooltip-text {
                position: absolute;
                z-index: 1;

                width: 300px;
                padding: 5px 10px;

                color: var(--neutral-04);
                text-align: center;

                visibility: hidden;
                background-color: var(--neutral-07);
                border-radius: 6px;
            }

            .tooltip:hover .tooltip-text {
                visibility: visible;
            }

            .tooltip .wrapped-text {
                font-family: inherit;

                border-bottom: 1px dotted;
            }

            .tooltip .tooltip-text::after {
                content: ' ';

                position: absolute;
                top: -9px;
                right: 50%;

                border-color: transparent transparent var(--neutral-07) transparent;
                border-style: solid;
                border-width: 5px;
            }

            .result_wrapper {
                @include row(flex-start, center, 10px);
                width: 100%;

                word-break: break-word;

                .icon {
                    min-width: 20px;
                    max-width: 20px;
                    height: 20px;

                    &.cancel {
                        cursor: pointer;

                        max-width: 15px;
                        height: 15px;

                        color: var(--neutral-04);
                    }
                }

                .tooltip .tooltip-text {
                    transform: translateX(-35%);
                }
            }

            .requirements_text {
                .tooltip .tooltip-text {
                    top: 130%;
                    transform: translateX(-30%);
                }
            }

            .upload_icon {
                width: 50px;
                height: 50px;
                margin-bottom: 10px;
            }

            .file_uploader {
                height: 100%;
            }

            .hidden {
                display: none;
            }

            .file-drop {
                width: 100%;
                height: 100%;
            }

            .file-drop > .file-drop-target {
                @include column(center, center, 0);

                width: 100%;
                height: 100%;
                min-height: 250px;
                margin: 0;
                padding: 5% 10%;

                border-radius: 40px;

                .text_container {
                    width: 100%;

                    text-align: center;

                    > * {
                        font-family: MontserratRegular, serif;
                        color: var(--neutral-04);
                    }

                    .actions_text {
                        margin-bottom: 20px;

                        font-size: 1.3rem;
                        line-height: 150%;
                    }
                }
            }

            .error {
                color: var(--service-02);
            }

            .select_manually_text {
                cursor: pointer;

                font-size: inherit;
                color: var(--primary-01);

                border-bottom: 2px dashed;
            }

            .file-drop > .file-drop-target.file-drop-dragging-over-target {
                background-color: #afd4ff;

                &.disabled {
                    background-color: var(--service-03);
                }
            }

            .uploading-process_container {
                @include column(center, center, 15px);
                width: 100%;

                .info {
                    font-size: 1.125rem;
                    color: var(--primary-01-dark);
                }

                .navigation-buttons_wrapper {
                    @include row(center, center, 15px);

                    .btn {
                        width: 120px;
                    }
                }
            }
        }
    }
}
