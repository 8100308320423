.tables-viewer {
    padding: 10px;

    &__list {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    &__name {
        margin-top: 10px;
    }

    &__table {
        table-layout: fixed;
        border-collapse: collapse;

        width: 100%;
        max-width: 100%;
        margin-top: 10px;
        padding: 5px;

        border: 1px solid var(--neutral-07);

        &.only-column {
            width: 50%;
        }

        th,
        td {
            overflow: hidden;

            padding: 5px;

            font-weight: 400;
            text-align: center;
            text-overflow: ellipsis;

            border: 1px solid var(--neutral-07);
        }

        th {
            background: var(--primary-01-extra-light);
        }
    }
}
