@import 'styles/flex-mixins';
@import 'styles/media';

#contact-us {
    @include laptop-s-max-width {
        > * {
            transform: scale(0.9);
        }
    }

    .content {
        @include landscape-min-width {
            width: 500px;
        }

        @include tablet-max-width {
            @include column(center, center, 14px);
            width: 90%;
        }

        @include column(center, center, 18px);
        position: relative;
        z-index: 0;

        text-align: center;

        &::before {
            @include tablet-max-width {
                content: none;
            }

            content: '';

            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: -10px;
            left: -235px;

            background: var(--main-bc) url('/assets/contact-us/background.png') no-repeat left center;
        }
    }

    .title {
        @include tablet-max-width {
            text-align: center;
        }
        margin-bottom: 36px;

        font-size: 32px;
        line-height: 36px;
        color: #06161d;
        text-transform: uppercase;
        letter-spacing: -0.05em;
    }

    .footer {
        @include row(flex-end, center, 0);

        @include tablet-max-width {
            justify-content: center;
        }

        width: 100%;

        .btn {
            width: 240px;

            font-size: 24px;
            line-height: 36px;
        }
    }
}
