@import 'styles/flex-mixins';
@import 'styles/media';

.main_landing {
    @include tablet-max-width {
        width: 100%;
    }
    overflow-x: hidden;

    width: 70%;
    height: 100%;

    &::-webkit-scrollbar-track {
        background-color: var(--orange-light) !important;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--orange) !important;
        border-radius: 0;
    }
}

.landing-section {
    @include column(center, center, 0);

    @include big-desktop-min-width {
        > * {
            transform: scale(1.3);
        }
    }
    @include tablet-max-width {
        height: 100vh;
    }
    z-index: 0;

    height: calc(100vh - var(--landing-header-height));
    padding: 0 5% var(--footer-height);

    background: var(--main-bc);

    > * {
        cursor: default;
    }
}
