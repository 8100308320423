.play_button_container {
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 30px;
    height: 30px;

    background: var(--primary-01);
    border-radius: 50%;

    .play_button {
        &--play {
            transform: translateX(15%);

            width: 0;
            height: 0;

            border-color: transparent transparent transparent var(--neutral-10);
            border-style: solid;
            border-width: 7px 0 7px 11px;
        }

        &--pause {
            width: 12px;
            height: 14px;

            border-right: 3px solid var(--neutral-10);
            border-left: 3px solid var(--neutral-10);
        }
    }

    &--light {
        background-color: transparent;
        border: 2px var(--primary-01) solid;
        border-radius: 50%;

        .play_button {
            &--play {
                border-color: transparent transparent transparent var(--primary-01);
            }

            &--pause {
                border-right: 3px solid var(--primary-01);
                border-left: 3px solid var(--primary-01);
            }
        }
    }
}
