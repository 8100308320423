.transcript-control {
    width: 100%;

    background-color: white;

    &__top {
        display: flex;

        padding: 1vw 1vw 0 1.75vw;

        > svg {
            margin: 3px;
        }
    }

    &__logo {
        cursor: pointer;
    }

    &__close {
        cursor: pointer;

        margin: 3px;
    }

    &__content {
        flex: 1;

        margin: 0 calc(var(--side-margin) - 1vw - 30px) 0 calc(var(--side-margin) - 1.75vw - 44px);

        > div {
            display: flex;
            gap: 15px;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 15px;
        }

        .player {
            flex: 1;
        }
    }

    &__name-wrapper {
        display: flex;
        gap: 25px;
        align-items: center;
    }

    &__readonly-label {
        padding: 5px 10px;

        font-size: 12px;
        color: var(--neutral-03);

        background-color: var(--neutral-08);
        border-radius: 4px;
    }

    &__upload-date,
    &__saving-date {
        padding: 0 35px;

        font-size: 12px;
        color: var(--neutral-03);
    }

    &__share {
        height: 36px !important;

        font-size: 14px !important;

        border-radius: 6px !important;
    }

    &__bottom {
        display: flex;
        align-items: center;

        height: 40px;
        padding: 0 1vw;

        background-color: var(--primary-01-light);
    }

    &__actions {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;

        margin: 0 calc(var(--side-margin) - 1vw - 24px) 0 calc(var(--side-margin) - 1vw - 12px);
    }

    &__protocol {
        justify-content: flex-end;

        height: 30px !important;

        font-weight: 700;
        color: #544080;

        border-radius: 4px !important;

        span {
            font-size: 14px;
        }
    }

    &__up {
        cursor: pointer;

        position: fixed;
        z-index: 10;
        right: 20px;
        bottom: 10px;

        width: 24px;
        height: 24px;

        text-align: center;

        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

        svg {
            transform: rotate(180deg);
        }

        &.hidden {
            visibility: hidden;
            opacity: 0;
        }

        &.visible {
            visibility: visible;
            opacity: 1;
        }
    }

    &__actions-group {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: center;

        margin: 0;
        padding: 0 12px;

        border-right: 1px solid var(--neutral-05);

        .btn {
            width: 32px;
            height: 30px;
            padding: 4px;

            svg {
                fill: #544080;
            }

            &-disabled {
                pointer-events: none;
                cursor: default;
            }

            &-disabled,
            &-inactive {
                svg {
                    fill: var(--neutral-04);
                }
            }
        }

        .tooltip {
            position: relative;

            display: inline-block;
        }

        .tooltip .tooltip_text {
            position: absolute;
            z-index: 2;
            top: 150%;
            left: 50%;
            transform: translateX(-50%);

            width: fit-content;
            min-width: 80px;
            padding: 5px;

            font-size: 0.85rem;
            text-align: center;

            visibility: hidden;
            background-color: #fff;
            border: 1px solid #fff;
            border-radius: 6px;
            box-shadow: 0 2px 3px #0000001f;
        }

        .tooltip .tooltip_text::after {
            content: '';

            position: absolute;
            bottom: 100%;
            left: 50%;

            margin-left: -5px;

            border-color: transparent transparent #fff;
            border-style: solid;
            border-width: 5px;
        }

        :not(.btn-disabled).tooltip:hover .tooltip_text {
            visibility: visible;
        }
    }
}
