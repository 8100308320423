@import 'styles/flex-mixins';

.decryption-info_container {
    @include column(center, flex-start, 20px);
    max-width: 500px;

    .progress_wrapper {
        width: 100%;
        max-width: 500px;

        .progressbar_wrapper {
            height: 30px;
        }

        .progress_percent {
            font-size: 36px;
            color: var(--neutral-00);
        }
    }

    .link {
        cursor: pointer;

        color: var(--primary-01);
    }
}
