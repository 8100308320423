@import 'styles/flex-mixins';
@import 'styles/media';

.search_filter {
    @include column(center, center, 2vh);

    @include landscape-max-width {
        width: 370px;
    }

    width: clamp(420px, 70%, 600px);
    max-width: 100%;

    .search_bar {
        width: 100%;
    }
}
