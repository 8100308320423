@mixin onclick-fade{
  transition: 0.25s opacity ease-out;
  
  &:active {
    opacity: 0.75;
  }
}

@mixin click-area($px){
  position: absolute;
  top: -#{$px}px;
  right: -#{$px}px;
  bottom: -#{$px}px;
  left: -#{$px}px;

  display: block;

  opacity: 0;
}
