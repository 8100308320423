@mixin mobile-s-max-width {
    @media only screen and (max-width: 320px) {
        @content;
    }
}
@mixin mobile-m-max-width {
    @media only screen and (max-width: 375px) {
        @content;
    }
}
@mixin mobile-l-max-width {
    @media only screen and (max-width: 425px) {
        @content;
    }
}
@mixin mobile-xl-max-width {
    @media only screen and (max-width: 485px) {
        @content;
    }
}

@mixin tablet-max-width {
    @media only screen and (max-width: 600px) {
        @content;
    }
}

@mixin tablet-min-width {
    @media only screen and (min-width: 600px) {
        @content;
    }
}

@mixin mobile-height {
    @media only screen and (max-height: 600px) {
        @content;
    }
}

@mixin landscape-max-width {
    @media only screen and (max-width: 801px) {
        @content;
    }
}

@mixin landscape-min-width {
    @media only screen and (min-width: 801px) {
        @content;
    }
}

@mixin laptop-xs-max-width {
    @media only screen and (max-width: 901px) {
        @content;
    }
}

@mixin laptop-s-max-width {
    @media only screen and (max-width: 1025px) {
        @content;
    }
}

@mixin laptop-s-min-width {
    @media only screen and (min-width: 1025px) {
        @content;
    }
}

@mixin laptop-m-max-width {
    @media only screen and (max-width: 1281px) {
        @content;
    }
}

@mixin laptop-m-min-width {
    @media only screen and (min-width: 1281px) {
        @content;
    }
}

@mixin laptop-l-max-width {
    @media only screen and (max-width: 1440px) {
        @content;
    }
}

@mixin laptop-l-min-width {
    @media only screen and (min-width: 1440px) {
        @content;
    }
}

@mixin big-desktop-min-width {
    @media only screen and (min-width: 1800px) {
        @content;
    }
}
