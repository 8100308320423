@import 'styles/mixins';

$toggle-width: 40px;
$toggle-padding: 4px;
$toggle-circle-diameter: 16px;

.toggle_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .default_toggle {
        cursor: pointer;
        user-select: none;

        position: relative;

        display: flex;
        align-items: center;

        width: $toggle-width;
        height: 24px;
        padding: 4px;

        background-color: var(--neutral-05);
        border-radius: 12px;

        &_button {
            @include transition(left 0.2s ease);

            position: absolute;
            left: $toggle-padding;

            width: $toggle-circle-diameter;
            height: $toggle-circle-diameter;

            background-color: #fff;
            border-radius: 50%;
        }

        &_label {
            margin-left: 5px;


        }

        &--active {
            background-color: var(--primary-01);

            .default_toggle_button {
                left: calc($toggle-width - $toggle-padding - $toggle-circle-diameter);
            }
        }

        &.left {
            &_button {
                left: 2px;
            }
        }
    }
}
