.archive_page {
    height: 100%;
    max-height: calc(100vh - var(--header-height) - var(--header-top-margin));
    padding-top: 3vh;

    .section_left {
        flex: 1;
    }

    .section_right {
        width: 35%;
    }
}
