@import 'styles/flex-mixins';
@import 'styles/media';

.record_file_uploader {
    @include column(initial, initial, 4vh);
    @include landscape-max-width {
        align-items: center;
    }

    display: flex;
    flex-direction: column;
    align-self: center;

    width: 90%;
    margin: 3vh 0 0;
    padding: 0 2%;

    &__title {
        font-family: MontserratBold, serif;
        font-size: 36px;
        line-height: 44px;
        color: var(--neutral-00);
    }

    &__content {
        @include row(space-between, flex-start, 3vw);

        @include laptop-xs-max-width {
            @include column-reverse(center, flex-start, 5vh);
        }
    }

    .steps_wrapper {
        @include big-desktop-min-width {
            width: 55%;

            .uploader_wrapper {
                width: 70%;
            }
        }
        flex: 1;
    }

    .info_wrapper {
        @include big-desktop-min-width {
            width: 40%;
        }
        @include laptop-xs-max-width {
            width: 90%;
        }
        @include mobile-xl-max-width {
            width: 100%;
        }
        @include mobile-l-max-width {
            flex-direction: column;
            align-items: center;
        }
        @include row(flex-start, flex-start, 0);

        width: clamp(500px, 40vw, 600px);
        height: fit-content;

        .info_icon {
            display: inline-block;
        }

        .info_text {
            width: fit-content;
            margin-left: 2vw;

            &__title {
                margin-bottom: clamp(10px, 2vw, 20px);

                font-family: MontserratBold, serif;
                font-size: clamp(1.4rem, 2vw, 1.6rem);
                line-height: 150%;
                color: var(--primary-01-dark);
            }

            &__body {
                font-family: MontserratRegular, serif;
                font-size: 1.1rem;
                line-height: 150%;
                color: var(--primary-01-dark);
            }
        }
    }
}
