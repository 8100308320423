.calendar_header {
    cursor: pointer;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: clamp(2rem, 5vh, 3rem);
    margin-bottom: 10px;

    color: var(--neutral-00);

    .current_date {
        flex: 1;

        padding: 0 2%;

        font-family: MontserratBold, serif;
        font-size: 0.9rem;
    }

    .reset {
        cursor: pointer;

        display: flex;
        align-items: center;

        color: var(--primary-01);

        span{
            margin-right: 5px;

            font-size: 0.9rem;
        }
    }
}
