@import 'styles/flex-mixins';

$animation: 0.6s;

.btn {
    @include row(center, center, 15px);
    cursor: pointer;

    height: 46px;
    padding: 5px 10px;

    font-size: 15px;
    text-align: center;

    border: none;
    border-radius: 12px;
    outline: none;

    transition: all 0.2s ease-out;

    &::first-letter {
        text-transform: capitalize;
    }

    &:disabled {
        cursor: auto;
    }

    &_primary {
        color: var(--neutral-10);

        background: var(--primary-01);

        &:disabled {
            opacity: 0.3;
        }

        &:hover:not([disabled]),
        &:active:not([disabled]) {
            background: var(--primary-01-dark);
        }
    }

    &_blue {
        color: var(--neutral-10);

        background: var(--blue-dark);

        &:disabled {
            opacity: 0.3;
        }

        &:hover:not([disabled]),
        &:active:not([disabled]) {
            background: var(--blue);
        }
    }

    &_transparent {
        color: var(--primary-01-dark);

        background: none;
        border: 1px solid var(--primary-01-dark);

        &:disabled {
            opacity: 0.3;
        }
    }

    &_secondary {
        color: var(--primary-02-dark);

        background: var(--primary-02-light);

        &:disabled {
            opacity: 0.3;
        }

        &:hover:not([disabled]),
        &:active:not([disabled]) {
            background: var(--primary-02);
        }
    }

    &_white {
        color: var(--primary-02-dark);

        background: var(--neutral-10);

        &:disabled {
            opacity: 0.3;
        }

        &:hover:not([disabled]),
        &:active:not([disabled]) {
            background: var(--primary-02-light);
        }
    }

    &_light {
        color: var(--neutral-02);

        background-color: transparent;
        border: 1px solid var(--neutral-05);
    }

    &_danger {
        color: var(--neutral-10);

        background-color: #dc3545;
        border: 1px solid #dc3545;

        &:hover {
            background-color: #bb2d3b;
        }
    }

    &_warning {
        color: var(--service-01);

        background-color: #FFE0D6;
        border: 1px solid transparent;
    }

    &_success {
        color: var(--neutral-10);

        background-color: var(--primary-02-dark);

        &:hover {
            background-color: #3b7056;
        }
    }

    &_orange {
        @include tablet-max-width {
            color: #004756;

            background: #ffaa1e;
        }
        color: var(--neutral-10);

        background: #f45800;

        &:disabled {
            @include tablet-max-width {
                color: #9eabae;

                background: rgb(255 170 30 / 20%);
            }
            color: #ffffff4d;
        }

        &:hover:not([disabled]) {
            @include tablet-max-width {
                color: #008088;
            }
            color: lightgray;
        }

        &:active:not([disabled]) {
            @include tablet-max-width {
                color: #032c35;
            }
            color: var(--neutral-10);
        }
    }

    &_with_icon {
        @include row(center, center, 0.6rem);

        * {
            color: inherit;
        }
    }

    // > *:last-child {
    //     margin-right: 15px;
    // }
}

.hamburger {
    user-select: none;

    position: relative;
    transform: scale(1);

    display: block;

    width: 68px;
    height: 68px;

    .path-burger {
        position: absolute;
        top: 0;
        left: 0;

        width: 68px;
        height: 68px;

        -webkit-mask-box-image: url('https://raygun.io/upload/mask.svg');
    }

    .animate-path {
        position: absolute;
        top: 0;
        left: 0;

        width: 68px;
        height: 68px;
    }

    .path-rotation {
        transform: rotate(0deg);
        transform-origin: 100% 0;

        width: 34px;
        height: 34px;
        margin: 34px 34px 0 0;

        &::before {
            content: '';

            display: block;

            width: 30px;
            height: 34px;
            margin: 0 4px 0 0;
        }
    }

    @keyframes rotate-out {
        0% {
            transform: rotate(0deg);
        }

        40% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes rotate-in {
        0% {
            transform: rotate(360deg);
        }

        40% {
            transform: rotate(180deg);
        }

        100% {
            transform: rotate(0deg);
        }
    }

    .path {
        stroke-dasharray: 240;
        stroke-dashoffset: 240;
        stroke-linejoin: round;
    }

    .burger-bun-top,
    .burger-bun-bot,
    .burger-filling {
        position: absolute;

        display: block;

        width: 36px;
        height: 4px;

        background: var(--orange);
        border-radius: 2px;
    }

    .burger-bun-top {
        top: 0;
        transform-origin: 34px 2px;
    }

    .burger-bun-bot {
        bottom: 0;
        transform-origin: 34px 2px;
    }

    .burger-filling {
        top: 12px;
    }

    &.is-open {
        .path {
            animation: dash-in $animation linear normal;
            animation-fill-mode: forwards;
        }

        .animate-path {
            animation: rotate-in $animation linear normal;
            animation-fill-mode: forwards;
        }

        .burger-filling {
            animation: burger-fill-out $animation linear normal;
            animation-fill-mode: forwards;
        }

        .burger-bun-top {
            animation: bun-top-out $animation linear normal;
            animation-fill-mode: forwards;
        }

        .burger-bun-bot {
            animation: bun-bot-out $animation linear normal;
            animation-fill-mode: forwards;
        }
    }

    &.is-closed {
        .path {
            animation: dash-out $animation linear normal;
            animation-fill-mode: forwards;
        }

        .animate-path {
            animation: rotate-out $animation linear normal;
            animation-fill-mode: forwards;
        }

        .burger-filling {
            animation: burger-fill-in $animation linear normal;
            animation-fill-mode: forwards;
        }

        .burger-bun-top {
            animation: bun-top-in $animation linear normal;
            animation-fill-mode: forwards;
        }

        .burger-bun-bot {
            animation: bun-bot-in $animation linear normal;
            animation-fill-mode: forwards;
        }
    }

    @keyframes dash-in {
        0% {
            stroke-dashoffset: 240;
        }

        40% {
            stroke-dashoffset: 240;
        }

        100% {
            stroke-dashoffset: 0;
        }
    }
    @keyframes dash-out {
        0% {
            stroke-dashoffset: 0;
        }

        40% {
            stroke-dashoffset: 240;
        }

        100% {
            stroke-dashoffset: 240;
        }
    }

    .burger-icon {
        position: absolute;

        width: 68px;
        height: 68px;
        padding: 20px 16px;
    }

    .burger-container {
        position: relative;

        width: 36px;
        height: 28px;
    }

    .burger-ring {
        position: absolute;
        top: 0;
        left: 0;

        width: 68px;
        height: 68px;
    }

    .svg-ring {
        width: 68px;
        height: 68px;

        > path {
            stroke: var(--orange);
        }
    }

    @keyframes bun-top-out {
        0% {
            top: 0;
            left: 0;
            transform: rotate(0deg);
        }

        20% {
            top: 0;
            left: 0;
            transform: rotate(15deg);
        }

        80% {
            top: 0;
            left: -5px;
            transform: rotate(-60deg);
        }

        100% {
            top: 1px;
            left: -5px;
            transform: rotate(-45deg);
        }
    }

    @keyframes bun-bot-out {
        0% {
            left: 0;
            transform: rotate(0deg);
        }

        20% {
            left: 0;
            transform: rotate(-15deg);
        }

        80% {
            left: -5px;
            transform: rotate(60deg);
        }

        100% {
            left: -5px;
            transform: rotate(45deg);
        }
    }

    @keyframes bun-top-in {
        0% {
            bottom: 0;
            left: -5px;
            transform: rotate(-45deg);
        }

        20% {
            bottom: 0;
            left: -5px;
            transform: rotate(-60deg);
        }

        80% {
            bottom: 0;
            left: 0;
            transform: rotate(15deg);
        }

        100% {
            bottom: 1px;
            left: 0;
            transform: rotate(0deg);
        }
    }

    @keyframes bun-bot-in {
        0% {
            left: -5px;
            transform: rotate(45deg);
        }

        20% {
            bottom: 0;
            left: -5px;
            transform: rotate(60deg);
        }

        80% {
            bottom: 0;
            left: 0;
            transform: rotate(-15deg);
        }

        100% {
            left: 0;
            transform: rotate(0deg);
        }
    }

    @keyframes burger-fill-in {
        0% {
            left: 36px;

            width: 0;
        }

        40% {
            left: 40px;

            width: 0;
        }

        80% {
            left: -6px;

            width: 36px;
        }

        100% {
            left: 0;

            width: 36px;
        }
    }

    @keyframes burger-fill-out {
        0% {
            left: 0;

            width: 36px;
        }

        20% {
            left: -6px;

            width: 42px;
        }

        40% {
            left: 40px;

            width: 0;
        }

        100% {
            left: 36px;

            width: 0;
        }
    }
}
