@import 'styles/flex-mixins';
@import 'styles/media';

#faq {
    .content {
        @include column(flex-start, center, 24px);

        @include laptop-s-max-width {
            max-width: 100%;
        }

        @include tablet-max-width {
            max-width: 85%;
        }

        overflow-y: auto;

        height: var(--content-height);
    }

    .title {
        padding-top: 64px;
        padding-bottom: 30px;

        font-size: 32px;
        line-height: 36px;
        text-align: center;
        letter-spacing: -0.05em;
    }

    .note {
        @include column(flex-start, flex-start, 6px);

        &__ask {
            @include row(flex-start, flex-start, 6px);

            > span {
                font-size: 18px;
                line-height: 24px;
                text-align: left;
                letter-spacing: 0;
            }

            &-img {
                width: 24px;
                height: 24px;
                margin-right: 6px;
            }
        }

        &__answer {
            > span {
                padding-left: 30px;

                font-family: Roboto, serif;
                font-size: 14px;
                line-height: 24px;
                text-align: left;
                letter-spacing: 0;
            }
        }
    }
}
