@import 'styles/generics';
@import 'styles/flex-mixins';

.dropdown_menu {
    position: absolute;
    z-index: 5;
    top: 100%;
    right: 0;
    transform: scaleY(0);
    transform-origin: top;

    padding: 5px 0;

    list-style: none;

    background-color: var(--neutral-10);
    border-radius: 4px;
    box-shadow: 0 30px 55px var(--shadow-3);

    transition: transform 0.2s ease-out;

    &__item {
        @include row(center, center, 0);
        cursor: pointer;

        width: 100%;
        min-height: 24px;
        padding: 5px;

        text-align: center;

        &:hover {
            background-color: var(--neutral-08);
        }
    }

    &--open {
        transform: scaleY(1);
    }
}
