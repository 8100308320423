@import 'flex-gap';

@mixin row($horizontal, $vertical, $gap) {
    @include gap($gap, row);
    display: flex;
    align-items: $vertical;
    justify-content: $horizontal;
}

@mixin column($horizontal, $vertical, $gap) {
    @include gap($gap, column);
    display: flex;
    flex-direction: column;
    align-items: $horizontal;
    justify-content: $vertical;
}

@mixin column-reverse($horizontal, $vertical, $gap) {
    @include gap($gap, column-reverse);
    display: flex;
    flex-direction: column-reverse;
    align-items: $horizontal;
    justify-content: $vertical;
}

@mixin wrap($horizontal, $vertical, $gap) {
    @include gap($gap, column-reverse);
    display: flex;
    flex-flow: column wrap;
    align-content: $horizontal;
    justify-content: $vertical;
}
