@import 'styles/flex-mixins';
@import 'styles/mixins';

.speakers_dropdown {
    display: flex;
    flex-wrap: wrap;

    &__header {
        cursor: pointer;
        user-select: none;

        display: flex;
        justify-content: space-between;

        svg {
            @include transition(fill 0.5s);
            fill: var(--neutral-08);
        }

        &__title {
            font-family: MontserratBold, serif;
        }
    }

    &__content {
        position: absolute;
        z-index: 5;

        display: flex;
        flex-direction: column;

        width: fit-content;
        min-width: 240px;
        max-width: 300px;
        max-height: 350px;
        margin-top: 25px;
        padding: 15px;

        background-color: white;
        border-radius: 20px;
        box-shadow: 0 2px 70px var(--shadow-2);

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            margin-bottom: 15px;

            span {
                font-family: MontserratSemibold, sans-serif;
                font-size: 1.125rem;
            }

            button {
                height: fit-content !important;

                color: var(--primary-01);

                background-color: transparent !important;
            }
        }

        &__list {
            overflow-y: auto;

            margin-bottom: 15px;
            padding-right: 10px;
            padding-bottom: 5px;

            border-bottom: 1px solid var(--primary-01-light);

            &__item {
                cursor: pointer;

                display: flex;
                align-items: center;

                width: 100%;
                min-height: 32px;
                padding: 0.3rem 1rem;

                color: var(--primary-01-dark);

                border-radius: 8px;

                &:not(:last-child) {
                    margin-bottom: 2px;
                }

                &:hover,
                &:focus {
                    background-color: var(--neutral-09);
                }

                &.selected {
                    background-color: var(--primary-01-light);
                }
            }
        }

        .default_checkbox {
            input {
                width: 1.25rem;
                height: 1.25rem;
            }

            label {
                font-size: 14px;
            }
        }

        .change-speaker-btn {
            height: 35px;
            margin-top: 10px;
        }
    }
}

.choose_speaker_modal {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-height: 50vh;

    .add_speaker_wrapper {
        @include row(space-between, flex-start, 10px);
        margin-top: 10px;

        .btn {
            flex: 1;
        }

        .input_wrapper {
            flex: 2;
        }
    }

    .speakers_list {
        overflow-y: auto;

        padding-right: 10px;
        padding-bottom: 5px;

        border-bottom: 1px solid var(--primary-01-light);

        &__item {
            cursor: pointer;

            display: flex;
            align-items: center;

            width: 100%;
            min-height: 32px;
            padding: 0.3rem 1rem;

            font-size: 1.1rem;

            border-radius: 8px;

            &:not(:last-child) {
                margin-bottom: 2px;
            }

            &:hover,
            &:focus {
                background-color: var(--primary-01-light);
            }
        }
    }
}
