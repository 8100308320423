@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/mixins';

.adding_dictionary_form {
    width: 100%;

    .btn {
        width: 100%;
        margin: 20px 0 10px;
    }
}

.dictionaries_page {
    padding: 6vmin 7vw;

    .page_title {
        margin-bottom: 40px;

        font-family: MontserratBold, serif;
        font-size: clamp(28px, 9vw, 35px);
    }

    .search_and_add_line {
        @include row(space-between, center, 20px);

        .search_bar {
            max-width: 580px;
        }

        .btn {
            width: 180px;
        }

        svg {
            fill: var(--primary-02-dark);
        }
    }

    .dictionaries_list {
        @include column(center, center, 5px);
        max-width: 700px;
        margin-top: 20px;

        .dictionary_card {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            height: 65px;

            .info {
                cursor: pointer;

                display: flex;
                flex: 1;
                align-items: center;
                justify-content: space-between;

                height: 100%;
                margin-right: 30px;
                padding: 0 10px;

                border-bottom: 2px solid var(--primary-01-light);

                .name {
                    font-family: MontserratBold, serif;
                }

                .count {
                    flex-shrink: 0;

                    margin-left: 10px;

                    color: var(--primary-01-dark);
                }
            }

            .actions {
                cursor: pointer;

                .icon {
                    @include transition(color 0.5s);

                    color: var(--neutral-07);

                    &:hover {
                        color: var(--primary-01);
                    }

                    &:not(:last-of-type) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
