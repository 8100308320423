.input {
    @include tablet-max-width {
        padding: 12px;
        padding-right: 40px;
    }
    position: relative;

    width: 100%;
    height: 46px;
    padding: 6px 12px;


    font-size: 1rem;
    color: var(--neutral-00);

    background: var(--neutral-10);
    border: 2px solid var(--primary-01-light);
    border-radius: var(--default-border-radius);
    outline: none;
    caret-color: var(--primary-01-dark);

    &::placeholder {
        color: var(--neutral-04);
    }

    &:disabled, &_inactive {
        background: #e4e4e475;
    }

    &:focus {
        border: 2px solid var(--primary-01);
    }

    &_with_icon {
        padding: 6px 37px 6px 12px;
    }
}

.error_message {
    width: 100%;
    padding-top: 0.3rem;

    font-size: 0.9rem;
    color: var(--service-01);
    text-align: start;
    overflow-wrap: break-word;
}

.textarea {
    resize: none;

    height: 108px;
}

.input_wrapper {
    position: relative;

    width: 100%;

    .label {
        display: block;

        padding-bottom: 0.5rem;
        padding-left: 0.5rem;


        font-size: 0.9rem;
        color: var(--neutral-04);
    }

    .showing_control {
        @include tablet-max-width {
            top: 15px;
        }
        position: absolute;
        top: 15px;
        right: 10px;

        display: inline-block;

        width: 20px;
        height: 20px;

        color: var(--primary-01);

        &--inactive {
            color: var(--neutral-05);
        }
    }

    .clear_icon {
        position: absolute;
        right: 15px;
        bottom: 12px;

        width: 20px;
        height: 20px;

        color: var(--neutral-04);

        &:hover {
            color: var(--neutral-02);
        }
    }
}

.select {
    height: 48px;
    padding: 5px;

    background: var(--neutral-10);
    border: 2px solid var(--primary-01-light);
    border-radius: var(--default-border-radius);
}
