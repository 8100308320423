@import 'styles/flex-mixins';
@import 'styles/media';

#greeting {
    &.landing-section_background {
        @include laptop-m-max-width  {
            > .content {
                @include column(center, flex-start, 25px);
            }
        }
        @include tablet-max-width {
            padding: 36px 24px;

            background: url('/assets/greeting/wooden-table-mobile.png') no-repeat;
            background-size: cover;
        }

        background: url('/assets/greeting/wooden-table.png') no-repeat;
        background-size: cover;
    }

    .content {
        @include row(center, center, 36px);

        @include tablet-max-width {
            @include column(flex-start, center, 24px);
        }

        @include landscape-max-width {
            > * {
                transform: scale(0.9);
            }
        }
    }

    .info {
        @include column(flex-start, center, 14px);

        max-width: 500px;

        &__title {
            font-size: clamp(26px, 6vw, 43px);
            line-height: 150%;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: -0.05em;
        }

        &__subtitle {
            @include tablet-max-width {
                max-width: 90%;
                padding-left: 0;
            }

            max-width: 380px;
            padding-left: 8px;

            font-size: 18px;
            line-height: 24px;
            text-align: left;
            text-transform: uppercase;
            letter-spacing: 0;
        }
    }

    .features {
        @include column(flex-start, center, 36px);

        @include tablet-max-width {
            @include column(flex-start, center, 12px);
        }

        &__item {
            @include row(center, center, 12px);

            &-icon {
                width: 60px;
                height: 60px;
            }

            &-text {
                font-family: 'Graphik LCG-bold', serif;
                text-align: left;
                letter-spacing: 0;
            }
        }
    }
}
