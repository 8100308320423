@import 'styles/scroll';
@import 'styles/media';
@import 'styles/UI/text';
@import 'styles/UI/select';
@import 'styles/UI/buttons';
@import 'styles/UI/divider';
@import 'styles/UI/dropdown';
@import 'styles/UI/playerButton';
@import 'styles/UI/input';

@font-face {
    font-family: 'Graphik LCG';
    src: url('./styles/fonts/GraphikLCG/GraphikLCG-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Graphik LCG-bold';
    src: url('./styles/fonts/GraphikLCG/GraphikLCG-Medium.woff2') format('woff2');
}

@font-face {
    font-family: MontserratRegular;
    src: url('./styles/fonts/Montserrat/Montserrat-Regular.ttf');
}
@font-face {
    font-family: MontserratMedium;
    src: url('./styles/fonts/Montserrat/Montserrat-Medium.ttf');
}
@font-face {
    font-family: MontserratSemiBold;
    src: url('./styles/fonts/Montserrat/Montserrat-SemiBold.ttf');
}
@font-face {
    font-family: MontserratBold;
    src: url('./styles/fonts/Montserrat/Montserrat-Bold.ttf');
}

.App {
    display: flex;
    flex-direction: column;

    max-width: 100vw;
    height: 100vh;
}

.App,
#portal {
    color: var(--neutral-00);
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    font-family: MontserratMedium, serif;
    font-size: 16px;
}

b {
    font-family: MontserratBold, serif;
}

body {
    overflow-x: hidden;

    background-color: var(--neutral-09);
}

.fullscreen {
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    min-width: 100vw;
    min-height: 100vh;

    background-color: #0303037e;
}

a {
    color: inherit;
    text-decoration: none;
}

:root {
    @include tablet-max-width {
        --landing-header-height: 70px;
    }

    @include mobile-height {
        --footer-height: 0;
    }

    --content-height: 330px;
    --landing-header-height: 96px;
    --header-height: 70px;
    --header-top-margin: 20px;
    --footer-height: 94px;
    --default-border-radius: 10px;
    --scroll-width: 18px;
    --main-bc: #f0e8db;
    --primary-01: #814feb;
    --primary-01-dark: #544080;
    --primary-01-light: #e5d9ff;
    --primary-01-light-text: #c6b7e7;
    --primary-01-extra-light: #f0ecfa;
    --primary-02: #7cecb6;
    --primary-02-dark: #408161;
    --primary-02-light: #e2ffe9;
    --neutral-00: #333;
    --neutral-01: #fbf9f7;
    --neutral-02: #6d6d6d;
    --neutral-03: #868686;
    --neutral-04: #9f9f9f;
    --neutral-05: #c5c5c5;
    --neutral-07: #E4E4E4;
    --neutral-08: #efefef;
    --neutral-09: #f5f5f5;
    --neutral-10: #fff;
    --orange: #ffaa1e;
    --orange-light: #fadfb1;
    --red-light: #ffe2e2;
    --blue-dark: #004756;
    --blue: #055a6b;
    --service-01: #f05721;
    --service-02: #fe6060;
    --service-03: #ff1a1a26;
    --shadow-1: #0000000d;
    --shadow-2: #4b4a4a1c;
    --shadow-3: #38383838;
    --side-margin: 5.5vw;
}
