@import 'styles/media';

.pagination_wrapper {
    text-align: center;

    > * {
        user-select: none;

        display: inline-block;
    }

    .page_number {
        @include landscape-max-width {
            width: 19px;
            height: 19px;
            margin: 0 2px;
        }
        cursor: pointer;

        width: 24px;
        height: 24px;
        margin: 0 4px;

        color: var(--neutral-02);

        background-color: var(--neutral-10);
        border: none;
        border-radius: 14px;

        &--active {
            color: var(--neutral-10);

            background: var(--primary-01);
        }
    }

    .page_divider {
        width: 24px;
        height: 24px;
        margin: 0 4px;

        color: var(--neutral-02);
    }

    .page_search {
        display: inline;

        margin: 0 3px;

        &__input {
            @include landscape-max-width {
                width: 60px;
            }
            width: 80px;
            height: 24px;
            padding: 2px 5px;

            background: var(--neutral-10);
            border: 1px solid var(--neutral-08);
            border-radius: 8px;

            &:focus {
                outline: none;
            }
        }
    }

    .arrow {
        cursor: pointer;

        width: 24px;
        height: 24px;
        margin: 0 4px;

        color: var(--neutral-04);

        &:hover {
            color: var(--primary-01);
        }

        &--disabled {
            cursor: default;

            color: var(--neutral-04);

            &:hover {
                color: var(--neutral-04);
            }
        }

        &--left {
            transform: rotate(90deg);
        }

        &--right {
            transform: rotate(-90deg);
        }
    }
}
