.cross {
    cursor: pointer;

    width: 1rem;
    height: 1rem;

    color: var(--neutral-05);

    &:hover {
        color: var(--neutral-04);
    }
}
