@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/generics';

.records_page_container {
    @include laptop-m-max-width {
        width: 100%;
    }

    @include landscape-max-width {
        height: auto;
    }

    overflow-y: hidden;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    .breadcrumb_wrapper {
        margin: 0 var(--side-margin) 20px;
    }

    .page_title {
        margin: 0 var(--side-margin) 35px;

        font-family: MontserratBold, serif;
        font-size: 2.25rem;
        color: var(--neutral-00);
    }

    .page_control {
        row-gap: 10px;

        margin: 0 var(--side-margin) 30px;
    }

    .page_content {
        @include landscape-max-width {
            overflow: auto;
            flex-direction: column-reverse;

            .section_left,
            .section_right {
                overflow-y: visible;

                width: 100%;
            }
        }

        overflow-y: hidden;
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;

        padding-top: 2vmin;

        section {
            overflow-y: auto;

            padding: 2vmin;
            padding-top: 0;
        }

        .section_left {
            @include laptop-l-max-width {
                align-items: flex-start;
            }
            @include landscape-max-width {
                align-items: center;

                padding-left: 3vmin;
            }

            padding-left: var(--side-margin);
        }

        .section_right:not(:empty) {
            @include column(flex-end, flex-start, 20px);
            @include laptop-l-max-width {
                align-items: flex-end;

                padding-right: var(--side-margin);
            }

            @include laptop-s-max-width {
                width: 45%;
            }
            @include landscape-max-width {
                align-items: center;

                width: 100%;
                padding-right: 2vmin;
            }

            padding-right: calc(var(--side-margin) + 2vw);
        }
    }
}
