@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/generics';

.archive_page .records_page_container {
    .page_title {
        .records_number {
            margin-left: 25px;

            font-size: 1.125rem;
            color: var(--neutral-04);
        }
    }

    .page_control {
        @include row(flex-end, center, 70px);

        @include tablet-max-width {
            flex-wrap: wrap;

            > *:not(:first-child) {
                margin-top: 10px;
            }

            .btn {
                flex: 1;
            }

            .sorting_wrapper {
                flex: 2;
            }
        }

        display: flex;

        margin: 0 35% 30px var(--side-margin);
        padding: 0 calc(2vmin + var(--scroll-width));

        .btn {
            width: 170px;
        }
    }
}
