.transcript-name {
    &__name-container {
        display: flex;
        gap: 10px;
    }

    &__edit-btn {
        svg {
            width: 15px;
            height: 15px;
        }
    }

    &__editable {
        .transcript-name__name-container {
            cursor: pointer;

            min-width: 200px;
            padding: 5px;

            border: 1px solid var(--primary-01-light);
        }

        .transcript-name__edit-btn {
            display: none !important;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: baseline;

        &:hover:not(.transcript-name__editable) {
            .transcript-name__edit-btn {
                display: block;
            }
        }

        .btn {
            display: none;

            height: 100%;
            padding: 0;

            color: var(--neutral-04);

            background-color: unset;
            border-radius: 0;
        }
    }

    &__content,
    &__input {
        overflow: hidden;

        width: fit-content;

        font-size: 18px;
        font-weight: 600;
        text-overflow: ellipsis;
    }

    &__input {
        width: 500px;

        border: none;
        outline: none;
        box-shadow: none;

        :active,
        :hover,
        :focus {
            outline: 0;
            outline-offset: 0;
        }
    }

    &__btn-container {
        display: flex;
        gap: 10px;
        justify-content: flex-end;

        width: 100%;

        .transcript-name__save-btn {
            color: var(--primary-01);
        }
    }

    &__editable &__save-btn,
    &__editable &__cancel-btn {
        display: block;
    }
}
