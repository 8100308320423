.uploaded-file {
    &_wrapper {
        display: flex;
        flex-direction: column;

        .link {
            cursor: pointer;

            color: var(--primary-01);
        }
    }

    &_link {
        display: flex;
        justify-content: start;

        margin-top: 15px;
    }

    &_name {
        font-weight: 600;
        color: var(--primary-01-dark);
    }
}
