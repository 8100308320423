@import 'styles/flex-mixins';
@import 'styles/media';
@import 'styles/on-click';

.auth_page {
    @include column(flex-start, flex-start, 20px);
    width: 100%;
    height: 100%;

    .header {
        @include row(space-between, center, 20px);
        width: 100%;
        padding: 35px 3% 10px;

        &__logo {
            flex: 1;

            max-width: 170px;

            &-image {
                width: 100%;
            }
        }

        .btn {
            flex: 1;

            max-width: 170px;
        }
    }
}

.confirmation_text {
    @include row(center, center, 20px);

    align-self: center;

    width: 65%;
    max-width: 700px;

    p {
        font-size: 1.4rem;
        color: var(--primary-01-dark);
    }

    .icon {
        width: 250px;
        height: fit-content;

        color: var(--primary-01-dark);
    }
}

.successful_confirmation_container {
    @include column(center, center, 20px);

    align-self: center;

    width: 65%;
    max-width: 700px;
    height: 100%;

    p {
        font-size: 1.4rem;
        color: var(--primary-01-dark);
    }

    .link {
        width: 100%;
        max-width: 180px;
    }

    .link .btn {
        width: 100%;
    }
}

.register_container,
.login_container,
.reset_password_container {
    @include column(center, center, 20px);

    align-self: center;

    width: 65%;
    max-width: 350px;
    height: 100%;

    .title {
        font-family: MontserratBold, serif;
        font-size: 2.6rem;
        text-align: center;
    }

    .section_form {
        @include column(center, center, 2vh);
        width: 100%;

        .link {
            font-family: MontserratBold, serif;
            color: var(--primary-01);
        }

        .btn {
            width: 100%;
        }

        .error_loader_container {
            margin: 0;
        }
    }
}
