@import 'styles/flex-mixins';
@import 'styles/media';

$row-height: 4vh;
$row-margin: 0.5vh;
$cell-width: 4vmin;
$cell-min-width: 25px;

.calendar {
    .calendar_cell {
        @include row(center, center, 0);
        flex: 1;

        height: 100%;

        background-color: var(--neutral-10);
        border: 1px solid transparent;
    }

    .calendar_table {
        @include row(space-between, baseline, 0);
    }

    .days_table {
        @include column(initial, space-between, $row-margin);

        flex: 1;

        .calendar_row {
            @include row(space-between, center, 0);
            height: $row-height;
        }

        .current {
            border: 1px solid var(--primary-01);
            border-radius: var(--default-border-radius);
        }

        .selected {
            position: relative;

            color: var(--neutral-10);


            &::before {
                content: '';

                position: absolute;
                z-index: 0;

                display: block;

                width: 100%;
                height: 100%;

                background-color: var(--primary-01);
                border: 1px solid var(--primary-01);
                border-radius: var(--default-border-radius);
            }
        }

        .period_cell,
        .left_side_of_range,
        .right_side_of_range {
            position: relative;

            color: var(--neutral-10);
        }

        .period_cell::before,
        .left_side_of_range::before,
        .right_side_of_range::before {
            content: '';

            position: absolute;
            z-index: 0;

            display: block;

            width: 100%;
            height: 100%;

            background-color: var(--primary-01);
            border: 1px solid var(--primary-01);
        }

        .left_side_of_range::before,
        .right_side_of_range::before {
            width: 100%;
            height: 100%;
        }

        .left_side_of_range::before {
            left: 0;

            width: 100%;

            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        .right_side_of_range::before {
            right: 0;

            width: 100%;

            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .number {
            z-index: 1;
        }

        .holiday_cell {
            color: var(--primary-01-dark);
        }
    }

    .weeks_table {
        @include column(center, center, $row-margin);

        margin-right: 6px;

        border-right: 1px solid var(--neutral-05);

        .weeks_cell {
            display: flex;
            align-items: center;

            width: $cell-width;
            min-width: $cell-min-width;
            height: $row-height;
        }

        .previous {
            color: var(--primary-02);
        }
    }

    .weekdays_bar {
        @include row(flex-end, center, 0);

        margin-bottom: $row-margin;
        padding-left: calc($cell-width * 1.15);

        color: var(--neutral-04);
    }
}
