@mixin transition($transition...) {
    --moz-transition: $transition;
    --o-transition: $transition;
    --webkit-transition: $transition;
    transition: $transition;
}

@mixin appearance($appearance...) {
    --moz-appearance: $appearance;
    --o-appearance: $appearance;
    --webkit-appearance: $appearance;
    appearance: $appearance;
}
