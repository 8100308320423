@import 'styles/generics';
@import 'styles/flex-mixins';
@import 'styles/mixins';

.transcript_list {
    position: relative;

    margin-top: 25px;
    padding: 0;

    overflow-wrap: anywhere;
    white-space: pre-wrap;

    background-color: var(--neutral-10);
    border-radius: var(--default-border-radius);
    outline: none;
}

*:focus {
    outline: 0 solid transparent;
}

.replica {
    position: relative;

    display: flex;
    flex-direction: column;

    padding: 20px 30px;

    &--not-editable {
        caret-color: transparent;
    }

    &__header {
        display: flex;
        align-items: center;

        margin-bottom: 10px;

        .speaker_name {
            margin-right: 10px;

            font-family: MontserratBold, serif;
            color: var(--primary-01-dark);
        }

        .speakers_dropdown {
            margin-right: 10px;
        }

        .play_button_container {
            @include transition(background-color 0.5s);

            min-width: 25px;
            height: 25px;
            margin: 0 1%;

            background-color: var(--neutral-08);

            &:hover {
                background-color: var(--primary-01);
            }

            .play_button {
                &--play {
                    border-width: 6px 0 6px 8px;
                }

                &--pause {
                    width: 8px;
                    height: 10px;

                    border-right-width: 2px;
                    border-left-width: 2px;
                }
            }
        }

        .audio_duration {
            @include transition(color 0.5s);

            font-family: MontserratBold, sans-serif;
            font-size: 0.9rem;
            color: var(--neutral-08);
        }

        .actions_container {
            @include row(flex-start, center, 10px);

            margin-left: auto;

            *[class$='-icon'] {
                @include transition(color 0.5s);

                cursor: pointer;

                color: var(--neutral-08);
            }

            .tooltip {
                position: relative;

                display: inline-block;
            }

            .tooltip .tooltip_text {
                position: absolute;
                z-index: 2;
                top: 150%;
                left: 50%;
                transform: translateX(-50%);

                width: fit-content;
                min-width: 80px;
                padding: 5px;

                font-size: 0.85rem;
                text-align: center;

                visibility: hidden;
                background-color: #fff;
                border: 1px solid #fff;
                border-radius: 6px;
                box-shadow: 0 2px 3px #0000001f;
            }

            .tooltip .tooltip_text::after {
                content: '';

                position: absolute;
                bottom: 100%;
                left: 50%;

                margin-left: -5px;

                border-color: transparent transparent #fff;
                border-style: solid;
                border-width: 5px;
            }

            .tooltip:hover .tooltip_text {
                visibility: visible;
            }
        }
    }

    &__content {
        .replica_text {
            flex: 1;

            padding-left: 15px;

            *::selection {
                background: var(--primary-01-light);
            }
        }

        .replica_text > :only-child {
            display: block;

            width: 100%;
            min-height: 100%;
        }

        .text_edit {
            padding: 5px;

            border: 1px solid var(--primary-01);
            border-radius: 8px;
        }
    }

    &:hover {
        * > {
            svg {
                fill: var(--neutral-05);

                &:hover {
                    fill: var(--primary-01);
                }
            }

            .play_button_container {
                background-color: var(--neutral-05);

                &:hover {
                    background-color: var(--primary-01);
                }
            }

            .audio_duration {
                color: var(--neutral-05);
            }

            .actions_container {
                *[class$='-icon'] {
                    color: var(--neutral-05);

                    &:hover {
                        color: var(--primary-01);
                    }
                }
            }
        }
    }
}
