@mixin gap($gap, $direction) {
    --fgp-gap: $gap;

    > * {
        --fgp-gap-item: #{$gap} !important;
        --fgp-gap: var(--fgp-gap-item) !important;

        @if $direction == column {
            margin: var(--fgp-gap) 0 0;

            &:first-child {
                margin-top: 0;
            }
        }
        @if $direction == column-reverse {
            margin: 0 0 var(--fgp-gap);

            &:last-child {
                margin-top: 0;
            }
        }
        @if $direction == row {
            margin: 0 var(--fgp-gap) 0 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
