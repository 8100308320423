@import 'styles/flex-mixins';
@import 'styles/media';

.profile_info_section {
    .section_title {
        margin-bottom: 1vmin;

        font-family: MontserratBold, serif;
        font-size: clamp(20px, 5vw, 36px);
        line-height: 44px;
        color: var(--neutral-00);
    }

    .user_info_form {
        @include column(flex-start, center, 3vmin);
        @include landscape-max-width {
            width: 90%;
        }
        @include tablet-max-width {
            width: 100%;
        }
        width: 360px;

        [class='input_wrapper']:last-of-type {
            margin-bottom: 10px;
        }

        .btn{
            width: 100%;
        }
    }

    .error_loader_container {
        @include column(center, center, 0);
        align-self: center;

        width: 100%;
        min-height: 4vmin;
        margin: 0 auto;
    }

    .change_password_btn {
        display: flex;
        align-items: center;
    }
}
