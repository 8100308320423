@import 'styles/flex-mixins';

.sorting_wrapper {
    @include row(center, center, 5px);
    cursor: pointer;

    flex-wrap: wrap;

    .sorting_tip {
        font-size: 1rem;
        color: var(--neutral-04);
        white-space: nowrap;
    }

    .sorting_property {
        position: relative;

        color: var(--primary-01);
        text-transform: lowercase;
        white-space: nowrap;

        .dropdown_menu {
            min-width: 150px;

            box-shadow: 0 13px 25px var(--shadow-3);

            &__item {
                @include row(flex-start, center, 0);
                width: 100%;
                height: 35px;
                padding: 5px 10px;

                color: var(--primary-01);
            }
        }
    }

    .sorting_order {
        .arrow {
            margin-left: 5px;

            &--up {
                transform: rotate(0);
            }

            &--down {
                transform: rotate(180deg);
            }
        }
    }
}
