@import 'styles/generics';
@import 'styles/flex-mixins';
@import 'styles/media';

.calendar {
    position: relative;

    width: 100%;
    padding: 10px 20px;

    color: var(--neutral-05);

    background-color: var(--neutral-10);
    border-radius: var(--default-border-radius);
    box-shadow: 0 10px 30px var(--shadow-1);

    .calendar_content {
        .disabled {
            color: var(--neutral-04);
        }
    }

    .calendar_body {
        display: none;

        margin-bottom: 20px;

        &--open {
            display: flex;
            flex-direction: column;
            align-items: initial;
            justify-content: center;
        }

        hr {
            margin: 0 -20px 15px !important;

            border: none;
            border-top: 2px solid var(--primary-01-light);
        }

        .show_records_button {
            width: 60%;
            height: 40px;
            margin-right: auto;
            margin-left: auto;
        }
    }

    .dropdown_menu {
        top: 100%;

        border: 0;
        box-shadow: none;
    }
}
