.progressbar_wrapper {
    position: relative;

    overflow: hidden;

    width: 100%;
    height: 8px;

    background-color: var(--primary-01-light);
    border: 1px solid #fff;
    border-radius: 20px;

    .progressbar-complete {
        position: absolute;
        top: 0;
        left: 0;

        height: 100%;

        background-color: var(--primary-01);

        .progressbar-liquid {
            position: absolute;
            top: -3px;
            right: -12px;

            width: 42px;
            height: 42px;

            background-color: var(--primary-01);
            border-radius: 40%;

            animation: r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
        }
    }


    @keyframes r {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}
