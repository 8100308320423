@import 'styles/flex-mixins';

.share-result-modal {
    width: 100%;
    padding: 0 15px;

    &__body {
        width: 550px;

        background-color: white;
    }

    &__title p {
        margin: auto;
        padding-left: 1rem;
    }

    &__add-email {
        @include row(space-between, flex-end, 12px);

        width: 100%;
        margin: 15px 0 0;

        label {
            flex-grow: 1;

            font-size: 11px;
            line-height: 14px;
            color: var(--neutral-04);

            input {
                margin-top: 6px;
            }
        }

        .btn {
            svg {
                width: 24px;
            }
        }
    }

    > p {
        font-size: 0.8rem;
        color: var(--neutral-02);
    }

    &__emails-container {
        overflow-y: auto;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-items: center;

        max-height: 250px;
        margin: 20px 0;
    }

    &__email {
        display: inline-flex;
        align-items: center;

        width: max-content;
        padding: 0 0 0 10px;

        white-space: nowrap;

        border: 1px solid var(--neutral-05);
        border-radius: 12px;

        span {
            font-size: 14px;
        }

        .btn_light {
            display: inline-flex;

            height: 30px;

            border: none;

            svg {
                width: 14px;
            }
        }
    }

    &__submit {
        @include row(center, unset, 0);

        margin: 20px 0;
    }
}
