.quill_text_editor {
    .ql-toolbar {
        background-color: var(--neutral-10);
        border: unset;
        border: 1px solid var(--primary-01-light-text);
        border-top-left-radius: var(--default-border-radius);
        border-top-right-radius: var(--default-border-radius)
    }

    .ql-container {
        font-size: 16px;
        white-space: pre-line;

        background-color: var(--neutral-10);
        border: unset;
        border-bottom-right-radius: var(--default-border-radius);
        border-bottom-left-radius: var(--default-border-radius);
    }


    &.disabled {
        .ql-toolbar {
            display: none;
        }

        .ql-container {
            border-top-left-radius: var(--default-border-radius);
            border-top-right-radius: var(--default-border-radius)
        }
    }

    .ql-editor {
        padding: 15px 24px;

        line-height: unset
    }
}
