@import 'styles/flex-mixins';

.profile_settings_section {
    .section_title {
        margin-bottom: 45px;

       font-family: MontserratBold, serif;
        font-size: 36px;
        line-height: 44px;
        color: var(--neutral-00);
    }

    .autosave_info {
        margin: 20px 0 40px;

        font-size: 0.9rem;
        line-height: 150%;
        color: var(--neutral-02);
    }

    .notifications {
        @include column(flex-start, center, 30px);

        &_title {
           font-family: MontserratSemibold, serif;
        }
    }
}
