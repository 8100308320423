.text_light {
    color: var(--neutral-04);
}

.text_bold {
   font-family: MontserratSemibold, serif;
}

.text_dark {
    color: #313131;
}

.text_success {
    color: secondary;
}

.text_large {
    font-size: 1.2rem;
}

.text_small {
    font-size: 0.9rem;
}
