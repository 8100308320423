@import 'styles/flex-mixins';
@import 'styles/media';

.router_tabs {
    @include row(center, center, 10px);

    margin: 0 30px;

    .tab {
        @include row(center, center, 0);
        @include laptop-s-max-width {
            flex-grow: 1;

            width: initial;
        }
        @include mobile-xl-max-width {
            height: 38px;
            min-height: 38px;
        }
        @include mobile-m-max-width {
            display: none;
        }

        cursor: pointer;

        width: 160px;
        min-height: 45px;
        padding: 7px 15px;


        text-align: center;

        border-radius: var(--default-border-radius);

        &_secondary {
            color: var(--primary-02-dark);

            background-color: var(--primary-02-light);
        }

        &_primary {
            color: var(--neutral-10);

            background-color: var(--primary-01);
        }

        .tooltip {
            position: relative;

            display: flex;
            align-items: center;

            width: 100%;
            height: 100%;

            & .tooltiptext {
                position: absolute;
                z-index: 1;
                top: 125%;
                left: 50%;

                width: 120px;
                margin-left: -60px;
                padding: 5px 0;

                color: #fff;
                text-align: center;

                visibility: hidden;
                opacity: 0;
                background-color: #555;
                border-radius: 6px;

                transition: opacity 0.3s;
            }

            & .tooltiptext::after {
                content: '';

                position: absolute;
                bottom: 100%;
                left: 50%;

                margin-left: -5px;

                border-color: transparent transparent #555;
                border-style: solid;
                border-width: 5px;
            }

            &:hover .tooltiptext {
                visibility: visible;
                opacity: 1;
            }

            .tab__icon {
                width: 27px;
                height: 30px;
            }
        }
    }
}
